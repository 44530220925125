<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" :rules="rules" label-width="110px">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item prop="name" label="名称">
                        <el-input size="small" v-model="formData.name" placeholder="请输入名称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="url" label="地址">
                        <el-input size="small" v-model="formData.url" placeholder="请输入地址"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="description" label="描述">
                        <el-input type="text" size="small" v-model="formData.description" placeholder="请输入描述"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item prop="sort" label="排序值">
                    <el-input-number size="small" v-model="formData.sort" :min="1" placeholder="请输入排序值"></el-input-number>
                </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label=" " label-width="110px">
                        <el-button size="small" type="" @click="cancel">取消</el-button>
                        <el-button size="small" type="primary" @click="save">保存</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
  </div>
</template>

<script>
import { addAPI, editAPI, getDetailAPI } from './api'

export default {
  name: 'Update',
  components: {  },
  data() {
    return {
      formData: {
        name: '',
        url: '',
        description: '',
        sort: 0,
      },
      type: 0,
      rules: {
        name: [{required: true, message: '名称必填', trigger: 'blur'}],
      }
    }
  },
  mounted() {
    if (this.$route.params.id) this.getDetail()
  },
  methods: {
    cancel() {
      this.$router.back(-1)
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail () {
      this.formData = await getDetailAPI(this.$route.params.id)
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }

    .course-editor {
      .el-form-item__content {
        width: 700px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
